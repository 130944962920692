import { useQuery } from "@tanstack/react-query";
import { ISeries } from "../../../utils/types/api.types";
import { ScoreRow } from "./scoreRow.component";
import { getTopScores } from "../scoreBoard.query";

export const TopScoreList = (props: { series: ISeries; hidden: boolean }) => {
  const { data, isLoading } = useQuery({
    queryKey: ["scores", props.series.id],
    queryFn: () => getTopScores(props.series.id),
  });
  if (props.hidden) {
    return <div></div>;
  }
  const scoreRows = data?.map((score, index) => (
    <ScoreRow
      key={index}
      position={index + 1}
      score={score}
    />
  ));
  return (
    <table>
      <tbody>{scoreRows}</tbody>
    </table>
  );
};
