import { formatScore } from "../../../utils/formatting.util";
import { IScore } from "../../../utils/types/api.types";

export const ScoreRow = (props: {
  position: number;
  score: IScore;
}) => {
  return (
    <tr className={props.score.time !== undefined ? "full-pull" : ""}>
      <td className="position">{props.position}</td>
      <td className="name">
        {props.score.first_name} {props.score.last_name}
      </td>
      <td className="score">{formatScore(props.score.distance, props.score.full_pull, props.score.time)}</td>
    </tr>
  );
};
