import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addSeries } from "../seriesList.query";
import { InputField } from "../../addScore/components/InputField.component";

export const SeriesForm = () => {
  const [name, setName] = useState("");
  const [color, setColor] = useState<"yellow" | "blue" | "green">("yellow");
  const [subtitle, setSubtitle] = useState("");

  const [validated, setValidated] = useState(false);
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation({
    mutationFn: addSeries,
    onSuccess: (data) => {
      console.log("Success", data);
      queryClient.invalidateQueries(["series"]);
      toast.success("Serie toegevoegd!");
    },
    onError: (error) => {
      console.log("error", error);
      toast.error(
        "Er is iets misgegaan bij het opslaan van de serie. Probeer het later opnieuw of neem contact op met de beheerder."
      );
    },
  });

  const isFormInvalid = () => {
    return name === "";
  };
  const handleSubmit = () => {
    if (isFormInvalid()) {
      setValidated(true);
      toast.error("De ingevulde gegevens zijn niet correct.");
      return;
    }

    mutate({
      data: {
        name,
        color,
        subtitle: subtitle === "" ? null : subtitle,
      },
    });
    setName("");
    setColor("yellow");
    setSubtitle("");
    setValidated(false);

  };
  console.log("valid", validated);

  return (
    <div className="add-score">
      <h1>Serie toevoegen</h1>
      <Row>
        <InputField
          validated={validated}
          
          name={"Naam"}
          value={name}
          setValue={setName}
          required={true}
          isValid={(value) => value !== ""}
        />
        <InputField
          validated={validated}
          name={"Bijschrift"}
          value={subtitle}
          setValue={setSubtitle}
        />

        <Col sm={12} md={6}>
          <Form.Label className="required">Kleur</Form.Label>
          <Form.Select
          value={color}
          onChange={(e) => setColor(e.target.value as "yellow" | "blue" | "green")}
        >
          <option value="yellow">Geel</option>
          <option value="blue">Blauw</option>
          <option value="green">Groen</option>
        </Form.Select>
        </Col>
      </Row>
      <Row>
        <Col offset={6} md={6}>
          <Button variant="success" disabled={isLoading} onClick={handleSubmit}>
            Toevoegen
          </Button>
        </Col>
      </Row>
    </div>
  );
};
