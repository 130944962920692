import { Navigate, Outlet } from "react-router-dom";
import { CustomNavbar } from "../components/customNavbar.component";

export const MainLayout = () => {
  if (!localStorage.getItem("token")) {
    return <Navigate to="/login" />;
  }
  return (
    <div>
      <CustomNavbar />
      <Outlet />
    </div>
  );
};
