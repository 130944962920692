import { useEffect, useState } from "react";
import {
  IScoreRanked,
  ISeries,
  ISocketData,
} from "../../utils/types/api.types";
import { TopScoreList } from "./components/topScoreList.component";
import { NewScore, NewScoreTemp } from "./components/newScore.component";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getActiveSeries } from "./scoreBoard.query";

export const ScoreBoard = () => {
  const queryClient = useQueryClient();
  const [message, setMessage] = useState<null | IScoreRanked>(null);
  const [seriesIndex, setSeriesIndex] = useState(0);

  const { data: seriesData, isLoading } = useQuery({
    queryKey: ["series"],
    queryFn: getActiveSeries,
  });

  const onOpen = () => {
    console.log("WebSocket connection established");
  };
  const onMessage = (event: MessageEvent) => {
    const data: ISocketData<"publishMessage", IScoreRanked> = JSON.parse(
      event.data
    );
    console.log("Message from server ", data);
    setMessage(data.data);
    queryClient.invalidateQueries(["scores", data.data.series.id]);
  };
  const onClose = () => {
    console.log("WebSocket connection closed. Reconnecting...");
    setTimeout(connectWebSocket, 1000); 
  };
  const connectWebSocket = (): WebSocket => {
    if (process.env.REACT_APP_WEBSOCKET_URL === undefined) {
      throw new Error("REACT_APP_WEBSOCKET_URL is not defined");
    }
    const socket = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);
    socket.addEventListener("open", onOpen);
    socket.addEventListener("message", onMessage);
    socket.addEventListener("close", onClose);
    return socket;
  };

  useEffect(() => {
    let socket: WebSocket = connectWebSocket();
    return () => {
      socket.removeEventListener("open", onOpen);
      socket.removeEventListener("message", onMessage);
      socket.removeEventListener("close", onClose);
      socket.close();
    };
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (message) {
      timeout = setTimeout(() => {
        const newSeriesIndex = seriesData?.findIndex(
          (series) => series.id === message.series.id
        );
        setSeriesIndex(newSeriesIndex ?? 0);
        setMessage(null);
      }, 5000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [message, seriesData]);

  useEffect(() => {
    let seriesTimeout: NodeJS.Timeout | null = null;
    queryClient.invalidateQueries(["series"]);
    if (!seriesData) {
      return;
    }
    seriesTimeout = setTimeout(() => {
      if (seriesData.length === 0 ) {
        return;
      }
      queryClient.invalidateQueries(["scores", seriesData[seriesIndex].id]);
      
      setSeriesIndex((seriesIndex + 1) % (seriesData ?? []).length);
    }, 5000);

    return () => {
      if (seriesTimeout) clearTimeout(seriesTimeout);
    };
  }, [seriesIndex, seriesData, queryClient]);


  useEffect(() => {
    const interval = setInterval(function() {
        console.log("refetching series");
        
        queryClient.invalidateQueries(["series"]);
    }, 1000);

    return () => {
      if (interval) clearInterval(interval);
    }
  }, [seriesData, queryClient])

  if (isLoading || !seriesData) return <div>Loading...</div>;

  const currentSeries: ISeries = seriesData[seriesIndex];
  if (!currentSeries) {
    if (seriesData.length > 0) {
      setSeriesIndex(0);
    }
    else {
      queryClient.invalidateQueries(["series"])
    }
  };
  const series = message ? message.series : currentSeries;
  if (!series) return <div>Series not found</div>;

  const seriesLists = seriesData.map((series, index) => (
    <TopScoreList
      key={index}
      series={series}
      hidden={series.id !== currentSeries.id}
    />
  ));
  return (
    <div className="scoreboard">
      <div className={`header ${series.color}`}>
        <div>
          <h1>{series.name}</h1>
          <h2>{series.subtitle ?? ""}</h2>
        </div>
        <h2 className="type">{series.is_try_out ? "Proef" : "Wedstrijd"}</h2>
      </div>
      <div className="scores">
        {message ? <NewScore score={message} /> : seriesLists}
      </div>
    </div>
  );
};
