import { makeUrl } from "../../utils/makeUrl.util";
import {
  IScoreRanked,
  ISeriesDetailed,
  IUpdateScore,
} from "../../utils/types/api.types";

export const getScores = async (
  series: number,
  orderBy: "name" | "score"
): Promise<ISeriesDetailed[]> => {
  const params = new URLSearchParams({ order_by: orderBy });
  if (series === -1) {
    const url = makeUrl(`/api/series/all_scores`);
    const response = await fetch(url + "/?" + params.toString());
    return response.json();
  }
  const url = makeUrl(`/api/series/${series}`);
  const response = await fetch(url + "/?" + params.toString());
  const result: ISeriesDetailed = await response.json();
  return [result];
};

export const updateScore = async (data: {
  data: IUpdateScore;
}): Promise<IScoreRanked> => {
  const { id } = data.data;
  const url = makeUrl(`/api/scores/${id}/`);
  let response;
  if (data.data.score === null && data.data.time === null) {
    response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    });
  }
  else {
    const payload = {
      distance: data.data.score === "FP" ? 100 : parseFloat(data.data.score),
      full_pull: data.data.score === "FP",
      time: data.data.time ?? null,
    }
     response = await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(payload),
    });
  }
  
  if (response.status === 401) {
    localStorage.removeItem("token");
    window.location.reload();
  }
  if (response.status < 200 || response.status >= 300) {
    throw new Error(await response.json());
  }
  return response.json();
};
