import { makeUrl } from "../../utils/makeUrl.util";
import { ICreateSeries, ISeries, ISeriesDetailed } from "../../utils/types/api.types";

export const updateSeries = async (data: {
    data: {id: number, active: boolean}
  }): Promise<ISeries> => {
   
    const { id, active } = data.data;
    const url = makeUrl(`/api/series/${id}/`);
  
    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        active: active,
      }),
    });
    if (response.status === 401) {
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (response.status < 200 || response.status >= 300) {
      throw new Error(await response.json());
    }
    return response.json();
  };

  
  export const addSeries = async (data: {
    data: ICreateSeries;
  }): Promise<ISeriesDetailed> => {
    const url = makeUrl(`/api/series/`);
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data.data),
    });
    if (response.status === 401) {
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (response.status < 200 || response.status >= 300) {
      throw new Error(await response.json());
    }
    return response.json();
  };