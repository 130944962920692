import { useQuery } from "@tanstack/react-query";
import { Form } from "react-bootstrap";
import { getSeries } from "../addScore.query";

export const SeriesSelect = (props: {
  value: number;
  setValue: (series: number) => void;
  validated: boolean;
  defaultOptionText?: string;
  defaultOptionEnabled?: boolean;
  hide_try_out?: boolean;
}) => {
  const { data: seriesData, isLoading } = useQuery({
    queryKey: ["series"],
    queryFn: getSeries,
  });
  let series = seriesData?? [];
  if (props.hide_try_out) {
    series = series.filter((series) => !series.is_try_out);
  }
  const items = series.map((series) => (
    <option key={series.id} value={series.id}>
      {series.name} {series.subtitle ?? ""} {props.hide_try_out ? "": series.is_try_out ? "(Proef)" : "(Wedstrijd)"}
    </option>
  ));
  return (
    <Form.Select
      disabled={isLoading}
      value={props.value}
      isInvalid={props.validated && props.value === -1}
      onChange={(e) => props.setValue(parseInt(e.target.value))}
    >
      <option disabled={!props.defaultOptionEnabled} value="-1">
        {props.defaultOptionText ?? "Kies een reeks"}
      </option>
      {items}
    </Form.Select>
  );
};
