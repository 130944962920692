import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { addScore } from "../addScore.query";
import { InputField } from "./InputField.component";
import { SeriesSelect } from "./seriesSelect.components";
import "react-toastify/dist/ReactToastify.css";

export const ScoreForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [series, setSeries] = useState(-1);
  const [city, setCity] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [validated, setValidated] = useState(false);

  const { mutate, isLoading } = useMutation({
    mutationFn: addScore,
    onSuccess: (data) => {
      console.log("Success", data);
      toast.success("Deelnemer toegevoegd!");
    },
    onError: (error) => {
      console.log("error", error);
      toast.error(
        "Er is iets misgegaan bij het opslaan van de deelnemer. Probeer het later opnieuw of neem contact op met de beheerder."
      );
    },
  });

  const isFormInvalid = () => {
    return firstName === "" || lastName === "" || series === -1;
  };
  const handleSubmit = () => {
    if (isFormInvalid()) {
      setValidated(true);
      toast.error("De ingevulde gegevens zijn niet correct.");
      return;
    }

    mutate({
      data: {
        series_id: series,
        first_name: firstName,
        last_name: lastName,
        city: city === "" ? undefined : city,
        vehicle: vehicle === "" ? undefined : vehicle,
      },
    });
    setFirstName("");
    setLastName("");
    setCity("");
    setVehicle("");
    setValidated(false);

  };
  console.log("valid", validated);

  return (
    <div>
      <Row>
        <InputField
          validated={validated}
          
          name={"Voornaam"}
          value={firstName}
          setValue={setFirstName}
          required={true}
          isValid={(value) => value !== ""}
        />
        <InputField
          validated={validated}
          name={"Achternaam"}
          value={lastName}
          setValue={setLastName}
          required={true}
          isValid={(value) => value !== ""}
        />

        <InputField
          validated={validated}
          name={"Gemeente"}
          value={city}
          setValue={setCity}
        />
        <InputField
          validated={validated}
          name={"Voertuig"}
          value={vehicle}
          setValue={setVehicle}
        />

        <Col sm={12} md={6}>
          <Form.Label className="required">Reeks</Form.Label>
          <SeriesSelect
            value={series}
            hide_try_out={true}
            setValue={setSeries}
            validated={validated}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Button variant="success" disabled={isLoading} onClick={handleSubmit}>
            Toevoegen
          </Button>
        </Col>
      </Row>
    </div>
  );
};
