import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";
import { ISeries } from "../../../utils/types/api.types";
import { updateSeries } from "../seriesList.query";
import { Form } from "react-bootstrap";

export const SeriesItem = (props: { serie: ISeries }) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: updateSeries,
      onSuccess: (data) => {
        console.log("Success", data);
        queryClient.invalidateQueries(["series"]);
        toast.success("Series bijgewerkt!");
      },
      onError: (error) => {
        console.log("error", error);
        queryClient.invalidateQueries(["series"]);
        setActive(props.serie.active)
        toast.error(
          "Er is iets misgegaan bij het bijwerken van de serie. Probeer het later opnieuw of neem contact op met de beheerder."
        );
      },
    });
    const onChange = () => {
      setActive(!props.serie.active)
      mutate({
        data: {
          id: props.serie.id,
          active: !props.serie.active,
        },
      });
    }
    const [active, setActive] = useState(props.serie.active);
      const {serie} = props;
      return <tr>
          <td>{serie.id}</td>
          <td>{serie.name}</td>
          <td>{serie.subtitle}</td>
          <td>{serie.color}</td>
          <td>{serie.is_try_out ? "Proef" : "Wedstrijd"}</td>
          <td>
            <Form.Check 
          type="switch"
          id="custom-switch"
          checked={active}
          disabled={isLoading}
          onChange={onChange}
          label={active ? "Actief" : "Inactief"}
        /></td>
      </tr>
  }