import { formatScore, rankToOrdinal } from "../../../utils/formatting.util";
import { IScoreRanked } from "../../../utils/types/api.types";

export const NewScore = (props: { score: IScoreRanked }) => {
  return (
    <div className="new-score">
      <h1>{formatScore(props.score.distance, props.score.full_pull, props.score.time)}</h1>
      <h1>
        {props.score.first_name} {props.score.last_name}
      </h1>
      <h2>{rankToOrdinal(props.score.rank)} plaats</h2>
    </div>
  );
};

export const NewScoreTemp = () => {
  return (
    <div className="new-score">
      <h1>{formatScore(24, false, null)}</h1>
      <h1>
    Nick Geuminckx
      </h1>
      <h2>10de plaats</h2>
    </div>
  );
};
