import { useMutation, useQuery } from "@tanstack/react-query";
import { Navigate } from "react-router-dom";
import { logout } from "./login.query";

export const Logout = () => {
  const { isSuccess, isError } = useQuery({
    queryFn: logout,
  });
  if (isSuccess || isError) {
    localStorage.removeItem("token");
    return <Navigate to="/login" />;
  }
  return <div>Logging out...</div>;
};
