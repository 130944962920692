import { Button, Form, Spinner } from "react-bootstrap";
import { IScore } from "../../../utils/types/api.types";
import { useState } from "react";
import { extractTime, formatTimeColon } from "../../../utils/formatting.util";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateScore } from "../scoreList.query";
import { toast } from "react-toastify";

export const ScoreItem = ({
  scoreItem,
  index,
}: {
  scoreItem: IScore;
  index: number;
}) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation({
    mutationFn: updateScore,
    onSuccess: (data) => {
      console.log("Success", data);
      queryClient.invalidateQueries(["scores"]);
      const originalScore = data.full_pull ? "FP" : data.distance?.toString() ?? "";
      const originalTime = data.time ? formatTimeColon(data.time) : "";
      setValidated(false);
      console.log(originalScore);
      
      setScore(originalScore);
      setTime(originalTime);
      toast.success("Score opgeslagen!");
    },
    onError: (error) => {
      console.log("error", error);
      toast.error(
        "Er is iets misgegaan bij het opslaan van de score. Probeer het later opnieuw of neem contact op met de beheerder."
      );
    },
  });
  const [validated, setValidated] = useState(false);
  const originalScore = scoreItem.full_pull ? "FP" : scoreItem.distance?.toString() ?? "";
  const originalTime = scoreItem.time ? formatTimeColon(scoreItem.time) : "";
  const [score, setScore] = useState<string>(originalScore);
  const [time, setTime] = useState<string>(originalTime);

  const isValidScore = (value: string) => {
    return value === "FP" || !isNaN(parseFloat(value));
  };
  const isValidTime = (value: string) =>
    value === "" || !isNaN(extractTime(value));

  const newTime = time === "" ? null : extractTime(time);
  const scoreChanged =
    score !== originalScore || scoreItem.time !== newTime;

  const submit = () => {
    if (!isValidScore(score) || !isValidTime(time)) {
      setValidated(true);
      return;
    }

      mutate({
        data: {
          id: scoreItem.id,
          score,
          time: time === "" ? undefined : extractTime(time),
        },
      });
    
  };

  const destroy = () => {
    // setScore("");
    // setTime("");
    // setValidated(false)
    mutate({
      data: {
        id: scoreItem.id,
        score: null,
        time: null,
      },
    });
  }
  
  return (
    <tr>
      <td>{index}</td>
      <td>{scoreItem.id}</td>
      <td>
        {scoreItem.first_name} {scoreItem.last_name}
      </td>
      <td>{scoreItem.city ?? "N/A"}</td>
      <td>{scoreItem.vehicle ?? "N/A"}</td>
      <td className="input-field-col">
        <InputField
          validated={validated}
          isValid={isValidScore}
          value={score}
          setValue={setScore}
          placeholder={"FP, 50"}
        />
      </td>
      <td className="input-field-col">
        <InputField
          validated={validated}
          isValid={isValidTime}
          value={time}
          setValue={setTime}
          placeholder="e.g. 1:12"
        />
      </td>
      <td className="input-field-btn">
        <Button
          onClick={scoreChanged ? submit : destroy}
          variant={scoreChanged ? (scoreItem.published ? "primary": "success") : "danger"}
        >
          {isLoading ? (
            <Spinner animation="border" size={"sm"} role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (scoreChanged ? (scoreItem.published ? "update": "save") : "clear")}
        </Button>
      </td>
    </tr>
  );
};

export const InputField = (props: {
  validated: boolean;
  value: string;
  setValue(value: string): void;
  placeholder: string;
  isValid(value: string): boolean;
}) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    props.setValue(value);
  };
  return (
    <div className="score-field">
      <Form.Control
        name="Score"
        isInvalid={
          !props.isValid(props.value) && (props.validated)
        }
        placeholder={props.placeholder}
        value={props.value}
        onChange={onChange}
      />
    </div>
  );
};
