import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Container, Form, Row, Table } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { getSeries } from "../addScore/addScore.query";
import { ISeries } from "../../utils/types/api.types";
import { useState } from "react";
import { updateSeries } from "./seriesList.query";
import { SeriesItem } from "./components/seriesItem.component";
import { SeriesForm } from "./components/seriesForm.component";

export const SeriesList = () => {
    const { data: seriesData, isLoading } = useQuery({
        queryKey: ["series"],
        queryFn: getSeries,
      });
      const seriesItems = seriesData?.map((series) => (
        <SeriesItem key={series.id} serie={series} />
      ));
  return (
    <Container className="list-score">
      <ToastContainer autoClose={4000} />
      <h1 className="title">Series</h1>
      <Row>
        <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Naam</th>
            <th>Bijschrift</th>
            <th>Kleur</th>
            <th>Type</th>
            <th>Actief</th>
          </tr>
        </thead>
        <tbody>{seriesItems}</tbody>
      </Table>
        </Row>
        <hr/>
        <SeriesForm/>
    </Container>
  );
};

