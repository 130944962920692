import { makeUrl } from "../../utils/makeUrl.util";
import { IScore, ISeries } from "../../utils/types/api.types";

export const getTopScores = async (series: number) => {
  const url = makeUrl(`/api/series/${series}/scores`);
  const params = new URLSearchParams({ limit: "5", published: "true" });
  const response = await fetch(url + "/?" + params.toString());
  const result: IScore[] = await response.json();
  return result;
};

export const getActiveSeries = async (): Promise<ISeries[]> => {
  const url = makeUrl(`/api/series`);
  const params = new URLSearchParams({ active: "true", other: "false"  });
  const response = await fetch(url + "/?" + params.toString());
  return response.json();
};