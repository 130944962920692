import { makeUrl } from "../../utils/makeUrl.util";
import {
  ICreateScore,
  IScoreRanked,
  ISeries,
} from "../../utils/types/api.types";

export const addScore = async (data: {
  data: ICreateScore;
}): Promise<IScoreRanked> => {
  const url = makeUrl(`/api/scores/`);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(data.data),
  });
  if (response.status === 401) {
    localStorage.removeItem("token");
    window.location.reload();
  }
  if (response.status < 200 || response.status >= 300) {
    throw new Error(await response.json());
  }
  return response.json();
};

export const getSeries = async (): Promise<ISeries[]> => {
  const url = makeUrl(`/api/series/`);
  const response = await fetch(url);
  return response.json();
};
