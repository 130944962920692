import { makeUrl } from "../../utils/makeUrl.util";
import { IToken } from "../../utils/types/api.types";

export const login = async (
  username: string,
  password: string
): Promise<IToken> => {
  const url = makeUrl(`/api-token-auth/`);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, password }),
  });
  if (response.status < 200 || response.status >= 300) {
    throw new Error(await response.json());
  }
  return response.json();
};

export const logout = async (): Promise<void> => {
  const url = makeUrl(`/api/scores/`);
  await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  });
};
