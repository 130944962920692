import { useMutation } from "@tanstack/react-query";
import { Button, Form, InputGroup } from "react-bootstrap";
import { login } from "./login.query";
import { useState } from "react";
import { Navigate } from "react-router-dom";

export const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [validated, setValidated] = useState(false);
  const { mutate, isError, isSuccess, isLoading } = useMutation({
    mutationFn: () => login(username, password),
    onSuccess: (data) => {
      localStorage.setItem("token", data.token);
    },
  });
  if (isSuccess) {
    return <Navigate to="/admin" />;
  }
  const submit = () => {
    if (username && password) {
      mutate();
    } else {
      setValidated(true);
    }
  };
  return (
    <div className="login-container">
      <div className="login">
        <h1>Login</h1>
        <Form.Label htmlFor="api-key">Gebruikersnaam</Form.Label>
        <InputGroup className="mb-3">
          <Form.Control
            id="Gebruikersnaam"
            value={username}
            isInvalid={(validated && !username) || isError}
            onChange={(e) => setUsername(e.target.value)}
          />
        </InputGroup>
        <Form.Label htmlFor="api-key">Wachtwoord</Form.Label>
        <InputGroup className="mb-3">
          <Form.Control
            id="Wachtwoord"
            value={password}
            isInvalid={(validated && !password) || isError}
            onChange={(e) => setPassword(e.target.value)}
            type={"password"}
          />
        </InputGroup>
        <Button
          className="login-btn"
          variant="primary"
          onClick={submit}
          disabled={isLoading}
        >
          Login
        </Button>
      </div>
    </div>
  );
};
