import { Navbar, Container, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

export const CustomNavbar = () => {
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand to="/" as={Link}>
          Trekker Trek
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-between"
        >
          <Nav>
            <NavLink to={"/"} name={"Score bord"} />
            <NavLink to={"/admin/scores/toevoegen"} name={"Deelnemer Toevoegen"} />
            <NavLink to={"/admin/scores/invullen"} name={"Score Invullen"} />
            <NavLink to={"/admin/series"} name={"Series"} />
          </Nav>
          <Nav>
            <NavLink to="/logout" name="Logout" />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

const NavLink = (props: { to: string; name: string }) => {
  const location = useLocation();

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  return (
    <Nav.Link to={props.to} as={Link} active={isActive(props.to)}>
      {props.name}
    </Nav.Link>
  );
};
