import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Col, Container, Form, InputGroup, Row, Table } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { ISeriesDetailed } from "../../utils/types/api.types";
import { SeriesSelect } from "../addScore/components/seriesSelect.components";
import { ScoreItem } from "./components/scoreItem.component";
import { getScores } from "./scoreList.query";

export const ScoreList = () => {
  const [series, setSeries] = useState(-1);
  const [orderBy, setOrderBy] = useState<"name" | "score">("name");
  const { data, isLoading } = useQuery({
    queryKey: ["scores", series, orderBy],
    queryFn: () => getScores(series, orderBy),
  });
  const seriesItems = data?.map((series) => (
    <SeriesItem key={series.id} series={series} />
  ));
  return (
    <Container className="list-score">
      <ToastContainer autoClose={4000} />
      <h1 className="title">Scores</h1>
      <Row>
        <Col sm={6}>
          <Form.Label>Filter op reeks</Form.Label>
          <InputGroup className="mb-3">
            <SeriesSelect
              value={series}
              setValue={setSeries}
              validated={false}
              defaultOptionEnabled={true}
              defaultOptionText={"Alle reeksen"}
            />
          </InputGroup>
        </Col>
        <Col>
          <Form.Label>Sorteren op: </Form.Label>
          <InputGroup className="mb-3">
            <Form.Select
              disabled={isLoading}
              value={orderBy}
              onChange={(e) =>
                setOrderBy(e.target.value === "score" ? "score" : "name")
              }
            >
              <option value="name">Naam</option>
              <option value="score">Score</option>
            </Form.Select>
          </InputGroup>
        </Col>
        <Col sm={12} offset={6}>
          {seriesItems}
        </Col>
      </Row>
    </Container>
  );
};

const SeriesItem = ({ series }: { series: ISeriesDetailed }) => {
  const scoreItems = series.scores.map((score, i) => (
    <ScoreItem index={i + 1} scoreItem={score} key={score.id} />
  ));
  return (
    <div>
      <h2>
        {series.name} {series.subtitle ? `(${series.subtitle})` : ""}
        <span className="float-end">
          {series.is_try_out ? "Proef": "Wedstrijd"}
        </span>
      </h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>ID</th>
            <th>Deelnemer</th>
            <th>Gemeente</th>
            <th>Voertuig</th>
            <th>Afstand</th>
            <th>Tijd</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{scoreItems}</tbody>
      </Table>
    </div>
  );
};
